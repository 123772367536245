import React, { Component } from 'react';
import { Footer } from '../Components/Footer';
import { Link } from 'react-router-dom';

let id = null;

class ArticleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            articleDetails: null,
            articleList: null,
            loading: false
        };
        id = window.location.href.split('/')[4];
    }

    componentDidMount() {
        id = window.location.href.split('/')[4];
        this.getArticle(id);       
    }

    componentWillUnmount() {        
    }

    getArticle(id) {
        fetch(process.env.REACT_APP_API_URL+`/postagens/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(resp => resp.json())
            .then(resp => {
                if (resp && resp.detail !== "Não encontrado.") {                    
                    this.setState({ articleDetails: resp });
                }                
                this.getAllArticles();
            }).catch(err => {
                console.log(err);
            })
    }

    getAllArticles() {
        fetch(process.env.REACT_APP_API_URL+'/postagens', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(resp => resp.json())
            .then(resp => {
                if (resp.results) {
                    const updatedArticleList = resp.results;
                    const filteredArticleList = updatedArticleList.filter(item => item.id !== id).slice(0, 5);

                    if (filteredArticleList.length > 0) {
                        this.setState({ articleList: filteredArticleList });
                    }
                }else {
                    this.setState({ articleList: [] });
                }               
            }).catch(err => {
                console.log(err);
            })
    }


    render() {
        const { articleDetails, articleList } = this.state;

        const isMobile = window.innerWidth <= 767;
        return (
            <div className='container' style={{ display: 'grid', gridTemplateRows: '1fr auto', minHeight: '92.5vh' }}>
                <div className='article-div'>
                    {
                        articleDetails ?
                            <div className='article-img-block'>

                                <img
                                    src={articleDetails.imagem}
                                    width="720"
                                    // height="120"
                                    // className="d-inline-block align-top article-image"
                                    alt={articleDetails.alt}
                                />
                                <div className='article-text-block'>
                                    <span className='article-title mt-5'>{articleDetails.titulo}</span>
                                    <span className='article-content mt-3'>{articleDetails.conteudo}</span>
                                </div>

                            </div>
                            :
                            <div style={{height: '100%', minWidth: '80%', display: 'flex', justifyContent: 'center'}}><span className='article-title'>Não foram encontrados dados para o artigo selecionado</span></div>
                            
                    }
                    {
                        isMobile || !articleList ? null :

                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '20px', width: '20%' }}>
                                <span className='article-title mt-5 mb-3'>Últimas Notícias</span>
                                {articleList.map((article) => (
                                    <Link key={article.id} to={`/article-details/${article.id}`} style={{ textDecoration: 'none', color: 'inherit' }}  onClick={()=> this.getArticle(article.id)}>
                                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '20px' }}>
                                            <img
                                                src={article.imagem}
                                                width="200"
                                                // height="120"
                                                // className="d-inline-block align-top article-image"
                                                alt={article.alt}
                                            />

                                            <span className='article-title-min mt-5'>{article.titulo}</span>

                                            <div style={{ border: '1px solid', borderColor: '#2e4688', height: '1px', width: '100%', marginTop: '10px' }}></div>


                                        </div>
                                    </Link>
                                ))}
                            </div>
                    }

                </div>
                {/* <div className='text-and-img-block align-items-center cards mt-4 mb-5'>
                   
                    {articleList.map((person) => (
                        <ArticleCard
                            key={person.id}
                            image={person.image}
                            alt={person.alt}
                            title={person.title}
                            text={person.text}
                        />
                    ))}
                </div> */}
                <Footer simple={true} />
            </div>
        );
    }
}

export default ArticleDetail;
