import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import Spinner from 'react-bootstrap/Spinner';

class Form extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            name: '',
            email: '',
            cellphone: '',
            message: '',
            loading: false,
        };

        this.state = { ...this.initialState };
    }

    handlerChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }

    resetFields = () => {
        this.setState(this.initialState);
    }

    setLoading = (loading) => {
        this.setState({ loading: loading });
    }

    validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    validatePhone = (phone) => {
        return /^\(?\d{2}\)?\s?9?\d{1}\.\d{4}-\d{4}$/.test(phone);
    }

    submitForm = () => {
        const { name, email, cellphone, message } = this.state;
        let errorMessage = '';

        if (!name.trim()) {
            errorMessage += 'Por favor, preencha o campo Nome corretamente.\n';
        }
        
        if (!this.validateEmail(email)) {
            errorMessage += 'Por favor, forneça um endereço de e-mail válido.\n';
        }
        
        if (!this.validatePhone(cellphone)) {
            errorMessage += 'Por favor, forneça um número de telefone válido.\n';
        }
    
        
        if (errorMessage) {
            alert(errorMessage);
        } 
        else {
            this.props.handlerSubmit({ name, email, cellphone, message });
        }
    }

    handlerChangeMask = (event, fieldName) => {
        const { value } = event.target;

        this.setState({
            [fieldName]: value,
        });
    }

    render() {
        const { name, email, cellphone, message, loading } = this.state;
        return (

            <form>
                <label htmlFor="name">SEU NOME</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={this.handlerChange} />
                <label htmlFor="email">SEU E-MAIL</label>
                <input
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handlerChange} />
                <label htmlFor="cellphone">SEU TELEFONE</label>
                <InputMask
                    className='inputmask'
                    mask='(99) 9.9999-9999'
                    maskChar=" "
                    value={cellphone}
                    onChange={(e) => this.handlerChangeMask(e, 'cellphone')}
                />
                <label htmlFor="message">SUA MENSAGEM</label>
                <textarea
                    type="text"
                    name="message"
                    id="message"
                    value={message}
                    onChange={this.handlerChange}
                    style={{
                        height: '150px',
                        maxHeight: '150px',
                        boxSizing: 'border-box',
                    }}
                />
                {loading ? (
                    <div className='spinner-block'>
                        <Spinner animation="border" role="status" variant="primary">
                        </Spinner>
                    </div>
                    
                ) : (
                    <input style={{ width: '100%', marginTop: '10px' }} type="button" value="Enviar" onClick={this.submitForm} />
                )}
            </form>

        );
    }
}

export default Form;
