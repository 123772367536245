import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class ArticleCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'home',
        };
    }

    handleSelect = (selectedKey) => {
        this.setState({ selectedTab: selectedKey });
    };


    render() {
        const { image, alt, title, text, id } = this.props;
        const isMobile = window.innerWidth <= 767;

        // const limit = isMobile ? 300 : 900;

        // let textFinal = text.substring(0, limit);

        // if (text.length > limit) {
        //     textFinal += "...";
        // }

        return (
            <Link to={`/article-details/${id}`} className='article-block' style={{ marginBottom: '29px', textDecoration: 'none', color: 'inherit' }}>
                {image && (
                    <img
                        src={image}
                        width="317"
                        height="200"
                        className="d-inline-block align-top article-image"
                        alt={alt}
                        style={{ maxHeight: isMobile ? '180px' : 'auto', maxWidth: isMobile ? 'min(100%, 767px)' : 'min(100%, 1140px)', minWidth: '317px' }}
                    />
                )}
                <div className='card-bar' style={{ borderLeft: '3px solid', borderColor: '#2e4688', height: '200px', marginLeft: '10px' }}>                    
                </div>
                <div className='card-person'>
                    <span className='article-title'>{title}</span>
                    <span className='article-content'>{text}</span>
                </div>
            </Link>
        );
    }
}

export default ArticleCard;
