import React, { Component } from 'react';
import { Footer } from '../Components/Footer';
import ArticleCard from '../Components/ArticleCard';

class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            articleList: [],
            loading: true,
            currentPage: 1,
            articlesPerPage: 25,
            totalPages: 0
        };
        this.getArticles();
    }

    componentDidMount() {
    }

    componentWillUnmount() {        
    }
    
    getArticles(page = 1) {
        fetch(`${process.env.REACT_APP_API_URL}/postagens?page=${page}&page_size=${this.state.articlesPerPage}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(resp => resp.json())
            .then(resp => {
                if (resp.results) {
                    this.setState({ 
                        articleList: resp.results,
                        totalPages: Math.ceil(resp.count / this.state.articlesPerPage),
                        currentPage: page,
                        loading: false
                    });
                } else {
                    this.setState({ loading: false });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false });
            });
    }

    handlePageChange = (page) => {
        this.setState({ loading: true }, () => {
            this.getArticles(page);
        });
    }

    renderPagination() {
        const { currentPage, totalPages } = this.state;
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => this.handlePageChange(i)}
                    disabled={i === currentPage}
                    style={{ margin: '0 5px', padding: '5px 10px', backgroundColor: i === currentPage ? '#314a8f' : '#ffffff', color: i === currentPage ? '#ffffff' : '#000000'}}                    
                >
                    {i}
                </button>
            );
        }

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                {pages}
            </div>
        );
    }

    render() {
        const { articleList, loading } = this.state;
        return (
            <div style={{ display: 'grid', gridTemplateRows: '1fr auto', minHeight: '92.5vh' }}>
                <div className="container">
                    <div className='text-and-img-block justify-content-evenly align-items-center'>
                        <span className='title-text color-white' style={{ zIndex: 1 }}>Artigos</span>

                    </div>
                    <img
                        src="/images/Listra.png"
                        className="d-inline-block align-top"
                        alt="Logo"
                        style={{
                            height: '150px',
                            width: '100%',
                            marginTop: '-120px'
                        }}
                    />
                    <div className='text-and-img-block align-items-center cards mt-4' style={{ marginBottom: '73px' }}>

                        {
                            articleList.length > 0 ?
                                articleList.map((article) => (
                                    <ArticleCard
                                        key={article.id}
                                        id={article.id}
                                        image={article.imagem}
                                        alt={article.titulo}
                                        title={article.titulo}
                                        text={article.resumo}
                                    />
                                ))
                                :
                                (
                                    loading ?
                                    <span className='title-text'>Carregando...</span>
                                    :
                                    <span className='title-text'>Sem artigos até o momento</span>
                                )
                        }
                    </div>
                    {this.renderPagination()}
                </div>

                <Footer simple={true} />
            </div>
        );
    }
}

export default Articles;
