import React, { Component } from 'react';
import Form from '../Components/Form';
import { Footer } from '../Components/Footer';


class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handlerSubmit = (contact) => {
        this.sendEmail(contact);
    }

    sendEmail(contact) {
        this.formRef.setLoading(true);

        fetch(process.env.REACT_APP_API_URL + '/send-email', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contact)
        }).then(resp => resp.json())
            .then(resp => {                
                this.formRef.resetFields();
                alert("E-mail enviado com sucesso! Entramos em contato em breve.");
            }).catch(err => {
                console.log(err);
                alert("Falha no envio do e-mail. Por favor, tente novamente em alguns instantes.");
            }).finally(() => {
                this.formRef.setLoading(false);
            });
    }

    setFormRef = (ref) => {
        this.formRef = ref;
    }


    render() {
        return (
            <div className="container">
                <div className='d-flex flex-direction-row align-items-center justify-content-center'>
                    <div className='p-5 contact-inner-block'>
                        <span style={{ color: '#314a8f' }}>Entre em contato</span>
                        {/* <Alert variant="warning">Contato</Alert> */}
                        {/* <h2>{this.state.date.toLocaleTimeString()}</h2>                     */}

                        <Form ref={this.setFormRef} handlerSubmit={this.handlerSubmit} />

                    </div>
                </div>

                <Footer simple={true} />

            </div>
        );
    }
}

export default Contact;
