import React, { Component } from 'react';
import { Footer } from '../Components/Footer';

class Monitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="container" style={{marginTop: '-20px'}}>
                <iframe className="iframe-monitor" src={`${process.env.REACT_APP_MONITOR_URL}/?embedded=true`} title="Monitor Frame"></iframe>
                <Footer simple={true}/>
            </div>
        );
    }
}

export default Monitor;
