import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'home',
            simple: false
        };
    }

    handleSelect = (selectedKey) => {
        this.setState({ selectedTab: selectedKey });
    };


    render() {
        const { selectedTab, simple } = this.props;

        const isMobile = window.innerWidth <= 767;

        const widthImg = isMobile ? 415 : 500;
        
        const currentPath = window.location.pathname.slice(1);

        return (

            <div className='footer-absolute'>
                <div className='info-block'>
                    {
                        simple ? null :
                            <div className='w-100'>

                                <div className='info-ask-block'>
                                    <img
                                        src="/images/Pag1 Img6.png"
                                        width={widthImg}
                                        // height="30"
                                        className="d-inline-block align-top img-tablet"
                                        alt="img"
                                    />

                                    <div className='info-ask'>
                                        <span style={{ zIndex: 1 }}>
                                            Precisa de ajuda com os dados?
                                        </span>

                                        <Link to="/contact" className='people-block' style={{ marginBottom: '29px', textDecoration: 'none', color: 'inherit' }}>
                                            <span className='talk-to-us' style={{ zIndex: 1 }} onClick={this.handleClickAcc}>
                                                Fale com a gente
                                            </span>
                                        </Link>


                                    </div>

                                </div>
                                <img
                                    src="/images/Quadrado 1 pag 2.png"
                                    // width="500"
                                    // height="30"
                                    className="d-inline-block align-top img-back"
                                    alt="Logo"
                                />
                            </div>
                    }
                    {
                        simple ? null :
                            <div className='info-line'>
                                <span>Municípios</span>
                                <span>Estados</span>
                                <span>União</span>
                            </div>
                    }

                </div>
                <Navbar bg="#2e4688" expand="lg" onSelect={this.handleSelect} activekey={selectedTab} style={{ padding: '1.28% 0 2% 10%' }}>
                    <Navbar.Brand href="/">
                        <img
                            src="/images/logo.png"
                            width="200"
                            // height="30"
                            className="d-inline-block align-top"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto nav-footer">
                            <Nav.Link
                                as={Link}
                                to="/"
                                eventKey="home"
                                style={{ color: currentPath === 'home' || currentPath === '' ? '#FFCC33' : 'white', fontSize: '18px', textDecoration: 'none' }}
                            >
                                Início
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/about"
                                eventKey="about"
                                style={{ color: currentPath === 'about' ? '#FFCC33' : 'white', fontSize: '18px', textDecoration: 'none' }}
                            >
                                Sobre Nós
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/article"
                                eventKey="article"
                                style={{ color: currentPath === 'article' ? '#FFCC33' : 'white', fontSize: '18px', textDecoration: 'none'  }}
                            >
                                Artigos
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/monitor"
                                eventKey="monitor"
                                style={{ color: currentPath === 'monitor' ? '#FFCC33' : 'white', fontSize: '18px', textDecoration: 'none' }}
                            >
                                Plataforma de Dados
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/contact"
                                eventKey="contact"
                                style={{ color: currentPath === 'contact' ? '#FFCC33' : 'white', fontSize: '18px', textDecoration: 'none' }}
                            >
                                Contato
                            </Nav.Link>                            
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

export default Footer;
